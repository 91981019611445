import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "../components/Home/HomePage";
import LandingPage from "../components/LandingPage/LandingPage";
import ProductPage from "../components/Product/ProductPage";
import StorePage from "../components/StorePicker/StorePage";
import CartPage from "../components/Cart/CartPage";
import CartStorePage from "../components/CartStore/CartStorePage";
import HelpPage from "../components/Help/HelpPage";
import NotFoundPage from "../components/NotFound/NotFoundPage";
import SummaryPage from "../components/Summary/SummaryPage";
import PriceComparisonSummary from "../components/PriceComparisonSummary/PriceComparisonSummaryPage";
import FavoriteProducts from "../components/User/FavoriteProducts";
import SavedCarts from "../components/User/SavedCarts";
import ResetPasswordRequest from "../components/ResetPasswordRequest/ResetPasswordRequest";
import ResetPasswordRequestForm from "../components/ResetPasswordRequestForm/ResetPasswordRequestForm";
//WhatInStock
import LocationPickerPage from "../components/WhatInStock/LocationPickerPage";
import ProductStatusPage from "../components/WhatInStock/ProductStatusPage";

//ProductMatchingTool
import PossibleExistingPage from "../components/ProductMatching/PossibleExistingMatchingGroupPage";
import PotentialMatchesPage from "../components/ProductMatching/PotentialMatchesPage";
import ProductMatchingGroupPage from "../components/ProductMatching/ProductMatchingGroupPage";
import ProductMatchingGroupListPage from "../components/ProductMatching/ProductMatchingGroupListPage";
import SimilarGroupsListPage from "../components/ProductMatching/SimilarGroupsListPage";
// import SimReviewInOnePage from "../components/ProductMatching/Sim2Review/SimReviewInOnePage";
import PbsReviewInOnePage from "../components/ProductMatching/Sim2Review/PbsReviewInOnePage";
import RapidMatchingTool from "../components/ProductMatching/Sim2Review/RapidMatchingTool";
import CoopMatchesPage from "../components/ProductMatching/Sim2Review/CoopMatchesPage";
import GoldenTestResult from "../components/ProductMatching/Sim2Review/GoldenTestResult";
import StoreTestResult from "../components/ProductMatching/Sim2Review/StoreTestResult";

import MLResultReviewPage from "../components/ProductMatching/MLReview/MLResultReviewPage";
import CheckNotUp2DateProductsPage from "../components/ProductMatching/CheckNotUp2DateData/CheckNotUp2DateProductsPage";
import SummaryReportPage from "../components/ProductMatching/CheckNotUp2DateData/SummaryReportPage";
import ParserManagePage from "../components/ParserManage/ParserManagePage";
import LinkManagePage from "../components/AdminDashboard/LinkManagePage";
import ProductSimilarPage from "../components/ProductSimilar/ProductSimilarPage";

// import ScrollToTop from "react-router-scroll-top";
import LearnMorePage from "../components/WhatInStock/LearnMorePage";
import CompanyReportToolPage from "../components/CompanyReportTool/CompanyReportToolPage";
import CompanyReportToolProductPage from "../components/CompanyReportTool/CompanyReportToolProductPage";
import CompanyReportToolCompetitorProductPage from "../components/CompanyReportTool/CompanyReportToolCompetitorProductPage";
import PotentialMatches4ProjectPage from "../components/ProductMatching/PotentialMatches4ProjectPage";
import TagListPage from "../components/Tags/TagListPage";
import TagDetailPage from "../components/Tags/TagDetailPage";
// import VersionUpdate from "../components/Version/VersionUpdate"; //remove 
// import SWVersionUpdate from "../components/Version/SWVersionUpdate"; //remove 



const loader = document.querySelector(".loader");
// const updateContainer = document.querySelector(".updateContainer");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => {
  loader.classList.add("loader--hide");
  // updateContainer.classList.add("loader--hide");
}

const AppRouter = () => {
  useEffect(hideLoader, []);
  
  return (
    <>
    {/* <SWVersionUpdate/> */}
    <BrowserRouter>
      {/* <ScrollToTop> */}
        <Switch>
          <Route path="/" component={LandingPage} exact={true} />
          <Route path="/home" component={HomePage} />
          <Route path="/stores" component={StorePage} />
          <Route path="/product/:id" component={ProductPage} />
          <Route path="/help" component={HelpPage} />
          
          <Route path="/pricecompare" component={SummaryPage} />
          <Route
            path="/pricecomparesummary"
            component={PriceComparisonSummary}
          />
          <Route path="/products/favorites" component={FavoriteProducts} />
          <Route path="/carts" component={SavedCarts} />
          <Route path="/cart" component={CartPage} />
          <Route path="/cartstore" component={CartStorePage} />
          <Route
            path="/resetpassword/request"
            component={ResetPasswordRequest}
            exact={true}
          />
          <Route
            path="/resetpassword/:token"
            component={ResetPasswordRequestForm}
          />
          {/* Covid-19 Help */}
          <Route path="/location-picker" component={LocationPickerPage} />
          <Route path="/product-status" component={ProductStatusPage} />
          <Route path="/learn-more" component={LearnMorePage} />
           {/* Admin Product Matching Tool  */}
          <Route path="/possible-existing/:id" component={PossibleExistingPage} />
          <Route path="/matching-potential/:id" component={PotentialMatchesPage} />
          <Route path="/matching-group/:id" component={ProductMatchingGroupPage} />
          <Route path="/matching-groups" component={ProductMatchingGroupListPage} />
          <Route path="/similar-groups" component={SimilarGroupsListPage} />
          {/* <Route path="/matching/sim/review" component={SimReviewInOnePage} /> */}
          <Route path="/matching/ml-review" component={MLResultReviewPage} />
          <Route path="/matching/pbs/review" component={PbsReviewInOnePage} />
          <Route path="/matching/rapid-matching-tool" component={RapidMatchingTool} />
          <Route path="/matching/pbs/coop" component={CoopMatchesPage} />
          <Route path="/matching/golden-test" component={GoldenTestResult} />
          <Route path="/matching/store-test" component={StoreTestResult} />
          <Route path="/matching/check-not-up-to-date-products" component={CheckNotUp2DateProductsPage} />
          <Route path="/matching/ood-summary-report" component={SummaryReportPage} />
          <Route path="/parser-manage/dashboard" component={ParserManagePage} />
          <Route path="/dashboard" component={LinkManagePage} />
          <Route path="/matching/similar" component={ProductSimilarPage} />
          {/* Admin Company Report Tool for Analytic  */}
          <Route path="/company-report-tool" component={CompanyReportToolPage} />
          <Route path="/company-report-tool-product/:id" component={CompanyReportToolProductPage} />
          <Route path="/company-report-tool-competitor-product/:id" component={CompanyReportToolCompetitorProductPage} />
          <Route path="/matching-potential-4project/:id/:projectid" component={PotentialMatches4ProjectPage} />
          <Route path="/tag/list" component={TagListPage} />
          <Route path="/tag/detail/:id/:name" component={TagDetailPage} />
          <Route component={NotFoundPage} />
        </Switch>
      {/* </ScrollToTop> */}
    </BrowserRouter>
    </>
  );
};

export default AppRouter;
