import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";
// import AccountCircle from "@material-ui/icons/Face";
import WarningIcon from "@material-ui/icons/Warning";

import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

import { Helpers } from "../../utility/helpers";
import { useSelector } from "react-redux";
import ProductAttributesInfo from "../Product/ProductAttributesInfo";
import ProductExternalLink from "../Product/ProductExternalLink";
import ProductPricesInfo from "../Product/ProductPricesInfo";
const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  matches: {},
  ingroup: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
}));

export default function ProductMatchingItem(props) {
  const history = useHistory();
  const classes = useStyles();
  const [direction, setDirection] = useState("up");
  const [checked, setChecked] = useState(true);
  const { productMatch, showType, groupId, productMatchingGroup } = props;
  //showType: 'original'||'potential'||'ingroup'
  
  const companies = useSelector(state => state.companies.companies);
  let productImageUrl = productMatch.image_url;
  const handInGroupRemove = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/remove/${productMatch.id}/g/${productMatch.product_matching_group_id}`;

    // console.log(postData);
    axios
      .delete(url, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        // console.log(response);
        setDirection("left");
        setChecked(false);
        // if(!response.data.data){
        //    history.push('/home');
        // }
      })
      .catch((err) => {
        console.log(err);
      });

    // onRemove&&onRemove(productMatch.id);
  };
  const handMatchesClick = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/match`;
    const postData = {
      productId: productMatch.id,
      groupId: groupId,
    };
    // console.log(postData);
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        setDirection("right");
        setChecked(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // onRemove&&onRemove(productMatch.id);
  };
  const handPotentialRemove = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/notmatch`;
    const postData = {
      productId: productMatch.id,
      groupId: groupId,
    };
    console.log(postData);
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        setDirection("left");
        setChecked(false);
      })
      .catch((err) => {
        console.log(err);
      });
    //  onRemove&&onRemove(productMatch.id);
  };
  const handSet1st = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/set1st`;
    const postData = {
      productId: productMatch.id,
      groupId: productMatch.product_matching_group_id,
    };
    // console.log(postData);
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        // setDirection("left");
        // setChecked(false);
        alert("Success, refresh to update the result!")
      })
      .catch((err) => {
        console.log(err);
      });

    // onRemove&&onRemove(productMatch.id);
  };
  const companyLogo = (companyName)=>{
    // console.log(companyName)
    // console.log(companies)
    const company = companies.find(x => x.name === companyName)

    return company?company.companyLogoLink:Helpers.companyLogo(companyName)
  }

  return (   
    
    <Slide direction={direction} in={checked} mountOnEnter unmountOnExit>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className={
            showType === "original"
              ? classes.original
              : showType === "potential"
              ? classes.potential
              : classes.ingroup
          }
        >
          <Card
            className={`${classes.card} ${
              showType === "original"
                ? classes.originalCard
                : showType === "potential"
                ? classes.potential
                : classes.ingroup
            }`}
          >
            <CardHeader
              title={
                <img
                  alt={productMatch.company_name}
                  style={{ height: "50px" }}
                  src={companyLogo(productMatch.company_name)}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/resources/images/no-picture-available.png";
                  }}
                />
              }
            />
            <CardMedia
              className={classes.media}
              component="img"
              image={productImageUrl}
              title={productMatch.name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/resources/images/no-picture-available.png";
              }}
            />
            <CardContent className={classes.cardContent}>
              <Typography className={classes.productName} variant="body2">
                {productMatch.fullname} / {productMatch.size}
              </Typography>
              <Typography className={classes.companyName} variant="body2">
                {/* {productMatch.company_name} */}
                {productMatch.size} #{productMatch.id} 
                {productMatch.type === "variety" && (
                  <Chip size="small" label={`Multi-variety`} />
                )}
              </Typography>
              <ProductExternalLink productId={productMatch.id}/>
              <ProductAttributesInfo productId={productMatch.id}/>
              <Typography className={classes.productName} variant="body2">
               <b>Code:</b>{productMatch.original_code}
              </Typography>

              {showType != "potential" && (
                <Typography className={classes.container} variant="body2">
                  <span className={classes.item}>ID:{productMatch.product_id}</span>
                  <span className={classes.item}>Manual Match</span>
                  <span className={classes.item}>
                    Score:{productMatch.match_score}
                  </span>
                  <span className={classes.item}>
                    {productMatch.manual_match_date &&
                      productMatch.manual_match_date.substr(0, 10)}
                  </span>
                  <span className={classes.item}>
                    L:{productMatch.lower_bound} U:
                    {productMatch.upper_bound}
                  </span>
                  <span className={classes.item}>
                    {productMatch.user_first_name}
                  </span>
                </Typography>
              )}
              <ProductPricesInfo productId={productMatch.id} limit={3}/>              
            </CardContent>
            {showType === "ingroup" && (
              <CardActions>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.notMatchButton}
                  onClick={handInGroupRemove}
                >
                  Remove
                </Button>
                {/* g:{productMatchingGroup.product_id} p:{productMatch.id} */}
                {productMatchingGroup.product_id!=productMatch.id&&(
                  <Button
                    // variant="outlined"
                    size="small"
                    onClick={handSet1st}
                  >
                    Make primary
                  </Button>
                )}
                
              </CardActions>
            )}
            {showType === "original" && (
              <CardActions>
                <Button size="small">Original</Button>
              </CardActions>
            )}
            {showType === "potential" && (
              <CardActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handMatchesClick}
                >
                  matches
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.notMatchButton}
                  onClick={handPotentialRemove}
                >
                  not a match
                </Button>
                {productMatch.product_matching_group_id && (
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={<WarningIcon />}
                    label={`Belonged to #${productMatch.product_matching_group_id}`}
                    color="secondary"
                    component="a"
                    href={`/matching-group/${productMatch.product_matching_group_id}`}
                    clickable
                  />
                )}
              </CardActions>
            )}
          </Card>
        </Grid>
              
    </Slide>
    
  );
}
