import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import AccountCircle from "@material-ui/icons/Face";
import WarningIcon from "@material-ui/icons/Warning";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";


import { Helpers } from "../../../../utility/helpers";
import { useSelector } from "react-redux";

import ProductAttributesInfo from "../../../Product/ProductAttributesInfo";
import DialogImageZoomIn from "../../../Product/DialogImageZoomIn";
import ProductExternalLink from "../../../Product/ProductExternalLink";
import ProductPricesInfo from "../../../Product/ProductPricesInfo";
const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 'auto',
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  // removeAcceptedButton: {
  //   color: "blue",
  //   borderColor: "blue",
  // },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  originalCard: {},
  matched: {
    // backgroundColor: theme.palette.primary.main,
  },
  matchedCard: {
    border: `2px solid`,
    borderColor: theme.palette.primary.main,
  },
  potential: {},
  potentialCard: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  lockedByYou: {
    backgroundColor: theme.palette.primary.main,
  },
  lockedByOther: {
    backgroundColor: "red",
    color: "#FFF",
  },
  wrongListLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "auto",
    fontSize: "0.8125rem",
  },
  moreButtons:{
    minWidth:"40px"
  }
}));

export default function ReviewItem(props) {
  const classes = useStyles();

  const {
    productMatch,
    showType,
  } = props;
  const companies = useSelector(state => state.companies.companies);
  //showType: 'original'||'potential'||'ingroup'
  // console.log(productMatch);
  //
  let productImageUrl = productMatch.image_url;

  
  const companyLogo = (companyName)=>{
    // console.log(companyName)
    // console.log(companies)
    const company = companies.find(x => x.name === companyName)

    return company?company.companyLogoLink:Helpers.companyLogo(companyName)
  }
  return (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={
          showType === "original"
            ? classes.original
            : productMatch.type === "matched"
            ? classes.matched
            : classes.potential
        }
      >
        <Card
          className={`${classes.card} ${
            showType === "original"
              ? classes.originalCard
              : productMatch.type === "matched"
              ? classes.matchedCard
              : classes.potentialCard
          }`}
        >
          <CardHeader
            title={
              <img
                alt={productMatch.company_name}
                style={{ height: "50px" }}
                src={companyLogo(productMatch.company_name)}
              />
            }
          />
          <DialogImageZoomIn imageUrl={productImageUrl}/>
          <CardMedia
            className={classes.media}
            component="img"
            image={productImageUrl}
            title={productMatch.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/resources/images/no-picture-available.png";
            }}
          />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.productName} variant="body2">
              {Helpers.titleCase(productMatch.fullname)}
            </Typography>
            <Typography className={classes.companyName} variant="body2">
              {/* {productMatch.company_name} */}
              {productMatch.size} #{productMatch.id}
            </Typography>
            <ProductExternalLink productId={productMatch.id}/>
            <ProductAttributesInfo productId={productMatch.id}/>
            <Typography className={classes.productName} variant="body2">
               <b>Code:</b>{productMatch.original_code}
            </Typography>

            <Typography variant="body2">
              {productMatch.product_matching_group_id && (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<WarningIcon />}
                  label={`Belonged to #${productMatch.product_matching_group_id}`}
                  color="secondary"
                  component="a"
                  href={`/matching-group/${productMatch.product_matching_group_id}`}
                  clickable
                />
              )}
            </Typography>
            <ProductPricesInfo productId={productMatch.id} limit={3}/> 
            
          </CardContent>
          
        </Card>
      </Grid>
  );
}
