import React from "react";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "../Heading/Heading";
const useStyles = makeStyles((theme) => ({
  '@global': {
    html:{
      textAlign: 'center',  		
		  background: 'url(./resources/images/spilt-milk-background-image.jpg) no-repeat top center fixed', 
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      backgroundSize: 'cover'  
    },   
    body: {
      padding: '10% 20%'
    },
    h1: {
      color: '#2650a2'
    }
  },
  betterCartlogo:{
    width: '150px',
  },
  betterCartButton:{
    color: '#fff',
		backgroundColor: '#00b04f',		
		fontSize: '20px',
		fontWeight: '600',
		lineHeight: '1.75',
		borderRadius: '4px',
		letterSpacing: '0.02857em',
		textTransform: 'uppercase',  
		maxWidth: '500px',
		marginLeft: 'auto',
		marginRight: 'auto',	
		marginTop:'40px',
		padding:'10px 20px 12px 20px',	
		display: 'block',
    textDecoration: 'none',
    '&:hover':{
      backgroundColor: 'rgb(0, 123, 55)'
    }
  }

}));
const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <>
      <Heading page={"notfoundpage"} />
      <img
        src="/resources/images/Better-Cart-Caps-Logo-square-no-tag.png"
        className={classes.betterCartlogo}
      />
      <br />
      <h1>Oops, we couldn't find what you were looking for.</h1>
      <Link className={classes.betterCartButton} to="/">
        Go back to BetterCart
      </Link>
    </>
  );
};

export default NotFoundPage;
