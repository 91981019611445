import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";

import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "rgba(255,255,0,0.3)",
    padding:'2px 8px 2px 8px',
    fontSize:'0.875rem'
  }, 
}));

export default function ProductAttributesInfo({productId,isPmg=false}) {
  const [dataSource, setDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const token = useSelector((state) => state.session.token);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product/get-attributes`;
      const postData = {
        isPmg: isPmg,
        id: productId,
      };
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
    };
    isVisible&&fetchData();

  }, [productId,isVisible]);
  
  return (
    <VizSensor onChange={doLoadData}>
       <p style={{fontSize:'0.75', color:'#33B0FF'}}>
        {!isVisible?(
                  <CircularProgress size={16} />
                ):(
                  <>
          {dataSource&&dataSource.productModel}
          </>
          )}
      </p>       
    </VizSensor>
  );
}
