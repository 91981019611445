import React, { useState, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import TaggedProductItem from "./TaggedProductItem";
import ItemLoading from "./ItemLoading";

import SearchBox from "./SearchBox";
import {  useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function TagDetailPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const tagId = props.match.params.id;
  const tagName = props.match.params.name;
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [products, setProducts] = useState([]);
  const token = useSelector(state => state.session.token);
  const loader = document.querySelector(".loader");
  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  }

  useEffect(() => {
    const fetchData = async () => {
      if (skip === 0) {
        showLoader();
        setIsLoading(true);
      }
      // console.log('here'+searchText);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/tagging/tagged-pmg?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        { 
          tagId: tagId,
          q: searchText },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      // console.log(result.data.data);
      // console.log('search')
      if (skip === 0) {
        setProducts(result.data.data);
      } else {
        //   console.log(result.data.data);
        //   console.log('herer');
        setProducts([...products, ...result.data.data]);
      }
      if (skip === 0) {
        setLoadMore(result.data.data.length === limit);
        setIsLoading(false);
        hideLoader();
      }
    };

    fetchData();
  }, [searchText, skip, order, orderBy]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  const handleSortClick = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSkip(0);
  };
  
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Tag: <span style={{background:"#DDD"}}>{tagName}</span> / {tagId}
          </Typography>
          <SearchBox doSearch={doSearch} placeholder={"Search by product's name"} value={searchText} searchOnChange={false} />
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
         
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Name</TableCell>
                  {/* <TableCell align="center">Commands</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row, i) => (
                  // <span>{row.image_url}</span>
                  <TaggedProductItem key={i} pmg={row}/>
                ))}
                {loadMore && (
                  <ItemLoading doLoadMore={doLoadMore} colSpanValue={4} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
