import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import envConfig from "../../bin/env.config";
import { Helpers } from "../../utility/helpers";
import { Utility } from "../../utility/utility";
import axios from "axios";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    paddingTop: "0px",
    margin: "16px",
    marginTop: "0px",
  },
  container: {
    padding: "8px",
  },

  listEmailsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function ShareProductToEmailContent(props) {
  const classes = useStyles();
  const [inputEmail, setInputEmail] = useState("");
  const [toEmails, setToEmails] = useState([]);
  const [message, setMessage] = useState("");
  const { product, productShareMail, onShareSuccess } = props;
  // imageSmallUrl
  // console.log(product);
  const shareUrl = `${envConfig.SHARE_DOMAIN}/product/${product._id}/province/${product.store.province}`;

  const productPrice = Helpers.isSpecialDiscount(product)
    ? Helpers.dealText(product)
    : Helpers.pricingText(product);

  const discountText =
    product.pricing.realizedPercentDiscount > 1
      ? `(${product.pricing.realizedPercentDiscount}% off)`
      : "";
  const productInfo = `${Helpers.fullProductNameWithBrand(product)} ${
    product.size
  }`;

  const regularPriceText =
    product.isOnSale && product.pricing.regularPrice
      ? `Reg ${product.pricing.regularPrice.toFixed(2)}`
      : "";
  const renderDealText = !Helpers.isSpecialDiscount(product)
    ? ""
    : Helpers.dealText(product);

  const renderPricePer = () => {
    return (
      (product.pricing.currentPriceLb !== 0
        ? "$" + product.pricing.currentPriceLb + "/lb"
        : "") +
      (product.pricing.currentPrice100 !== 0
        ? " $" + product.pricing.currentPrice100 + "/100"
        : "") +
      (product.pricing.currentPriceLb !== 0 &&
      product.pricing.currentPrice100 !== 0
        ? "g"
        : "") +
      (product.pricing.currentPriceLb === 0 &&
      product.pricing.currentPrice100 !== 0
        ? "ml"
        : "")
    );
  };
  const savePrice =
    Helpers.savePrice(product) !== ""
      ? "\r\n You save $" + Helpers.savePrice(product)
      : "";

  const productImageUrl =
    product.imageSmallURL.length > 0
      ? product.imageSmallURL
      : `/resources/images/no-picture-available.png`;

  const renderMailText = () => {
    return ` ${message}\r\n
        BetterCart has this amazing deal: 
        ${productInfo} for ${productPrice} ${discountText}! ${regularPriceText}\r\n
        ${savePrice}
        View it at: ${shareUrl}\r\n\r\n
        What is BetterCart?\r\n
        BetterCart is helping grocery shoppers across Canada save the most money on their grocery bill.  How do we do it?  We compare prices across your local grocery stores so you always get the best deal.  There's nothing like saving up to 70% on grocery items, and that's possible with BetterCart!
        \r\n
        Come visit us today at https://betterCart.ca
        \r\n
        Want to learn more? Drop me a line at melanie@bettercart.ca. 
        \r\n
        Melanie Morrison, CEO.   
      `;
  };
  const renderMailHTML = () => {
    return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
      <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="x-apple-disable-message-reformatting">
        <meta name="format-detection" content="telephone=no">
        <meta name="format-detection" content="address=no">
        <title>Check out this amazing deal on BetterCart</title>
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" type="text/css">
        <style type="text/css">
        @media screen and (min-width:500px) {
        
        }
        #BCEmail {

        }
        </style>
      </head>
      <body  id="BCEmail">
      <table width="100%" border="0" style="background-color:#DDD" cellspacing="0" cellpadding="0">
          <tr>
              <td align="center">
              <table width="600px"  style="background-color:#FFF" border="0" cellspacing="0" cellpadding="0">
              <tr>
              <td align="center">
              <img src="/resources/images/BC-Logo.png"><br>
              <p>
              ${message}
              </p>
              <p>BetterCart has this amazing deal:</p>
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%; max-width: 50px; ">
              <tr>
                <td align="center" width="33%">        
                  <img src="${productImageUrl}" width="200"><br>
                </td>
                <td>
                  <strong>${productInfo}</strong> for ${productPrice} ${discountText}! ${regularPriceText}<br>
                  ${savePrice}<br>
                  ${renderDealText}<br>
                  ${renderPricePer()}<br>            
                  ${product.dateText}<br> 
                  <strong>${product.store.company}</strong><br>
                  <i>${product.store.name} - ${product.store.address1}</i><br>
                  <br>
                  <a href="${shareUrl}" target="_blank" style="color:#085ff7;display:inline-block;font-weight:bold;font-size:16px;text-align:center;text-decoration:none;">View price comparisons for this product</a><br>  
                  <br>
                  <a href="https://betterCart.ca" target="_blank" style="color:#00b04f;display:inline-block;font-weight:bold;font-size:16px;text-align:center;text-decoration:none;">Check out more great deals on BetterCart</a>
                            
                </td>
              </tr>
              </table>  
              <br>
              <strong>What is BetterCart&trade;?</strong><br>
              <p>
              BetterCart is helping grocery shoppers across Canada save the most money on their grocery bill.  How do we do it?  We compare prices across your local grocery stores so you always get the best deal.  There's nothing like saving up to 70% on grocery items, and that's possible with BetterCart!
              </p>
              <p>Come visit us today at <a href="https://betterCart.ca" target="_blank" style="text-decoration:none;">BetterCart.ca</a>!</p>
              <p>Want to learn more? Drop me a line at melanie@bettercart.ca.</p> 
              
              <strong>Melanie Morrison, CEO.</strong> 
              </td>
          </tr>
        </table>
              </td>
          </tr>
      </table>
     
       
      </body>
      <html>          
    `;
  };

  const onChangeMessage = (e) => {
    setMessage(e.target.value);
    //setError(false);
  };
  const onChange = (e) => {
    setInputEmail(e.target.value);
    //setError(false);
  };

  const keyPressed = ({ key }) => {
    if (key === "Enter" || key === "Tab") {
      handleClick();
    }
  };

  const handleClick = () => {
    let emails = [...toEmails];
    inputEmail.split(",").map((email) => {
      email = email.trim();
      if (!email || email.length === 0 || !Utility.isEmailValid(email)) {
      } else {
        emails = [...emails, email];
      }
    });
    setToEmails((toEmails) => emails);
    setInputEmail("");
  };
  const handleRemove = (email) => {
    setToEmails((toEmails) => [...toEmails].filter((x) => x != email));
  };
  const submitHandler = (e) => {
    // Prevent form submission on Enter key
    e.preventDefault();
  };
  const onShare = () => {
    let emails = [];
    inputEmail.split(",").map((email) => {
      email = email.trim();
      if (!email || email.length === 0 || !Utility.isEmailValid(email)) {
      } else {
        emails = [...emails, email];
      }
    });
    setToEmails((toEmails) => emails);
    if (emails.length > 0) {
      let subject = `Check out this amazing deal, "${product.name}" on BetterCart!`;
      // let subject = `Check out this amazing deal on BetterCart!`;

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/productshareemail`;
      let email = {
        from: "noreply@bettercart.ca",
        to: emails,
        subject: subject,
        text: renderMailText(),
        html: renderMailHTML(),
      }; // console.log(url);
      // console.log(email);
      axios
        .post(url, email)
        .then((response) => {
          console.log(response);
          onShareSuccess();
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
    }
  };

  return (
    <form className={classes.root} onSubmit={submitHandler}>
      <Typography color="primary" align="left">
        <i>
        You can enter your friend's email here.  More than one friend?  Separate their email addresses with a comma.
        </i>
      </Typography>

      <div className={classes.container}>
        <TextField
          label="Emails"
          // placeholder="Who do you want to share this with?"
          fullWidth
          onChange={onChange}
          // onKeyPress={keyPressed}
          variant="outlined"
          value={inputEmail}
        />
      </div>
      <div className={classes.listEmailsContainer}>
        {toEmails.map((email, i) => (
          <Chip label={email} onDelete={() => handleRemove(email)} />
        ))}
      </div>
      <Typography color="primary" align="left">
        <i>Would you like to personalize the message you're sending to your friend(s)?  Type here. If not, we'll send this message to them so they know what's happening.</i>
      </Typography>
      <div className={classes.container}>
        <TextField
          label="Message"
          defaultValue="Hi Friends! BetterCart has this amazing deal, and I wanted you to know about it."
          fullWidth
          variant="outlined"
          multiline={true}
          rows={4}
          onChange={onChangeMessage}
        />
      </div>

      <div className={classes.container}>
        <Button
          onClick={onShare}
          color="primary"
          variant="contained"
          fullWidth={true}
          disabled={!inputEmail.length}
        >
          Share Now
        </Button>
      </div>
    </form>
  );
}
