import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@material-ui/core';
import axios from "axios";
import envConfig from "../../bin/env.config";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";
const useStyles = makeStyles((theme) => ({
  groupLink:{
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center"
  } 
}));

export default function ProductExternalLink({productId,isPmg=false}) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  }; 
  const token = useSelector((state) => state.session.token);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product/get-external-link`;
      const postData = {
        isPmg: isPmg,
        id: productId,
      };
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
    };
    isVisible&&fetchData();

  }, [productId,isVisible]);
  
  return (
    <VizSensor onChange={doLoadData}>
      <div>
        {!isVisible?(
          <CircularProgress size={16} />
        ):(
          <>
            {dataSource && (
                        <a
                          href={dataSource}
                          target="_blank"
                          className={classes.groupLink} 
                        >
                          <VisibilityIcon/> View on store site
                        </a>
                      )}
          </>
          )}
      </div>
    </VizSensor>
           
  );
}
