import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
// import CountTaggedProducts from "./CountTaggedProducts";
import ProductImage from "../Common/ProductImage.js";
import Tags from "./Tags";

const useStyles = makeStyles((theme) => ({
  pmgId: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: "pointer"
  }, 
}));

export default function TaggedProductItem({
  pmg,
}) {
  const history = useHistory();
  const classes = useStyles();

  const [direction, setDirection] = useState("right");
  const [checked, setChecked] = useState(true);  
  const openProductMatchingGroup = (groupId) => {
    window.open(`/matching-group/${groupId}`);
  };
  return (
    <Slide direction={direction} in={checked} mountOnEnter unmountOnExit>
      <TableRow key={pmg.id}>
        <TableCell align="center">
          <Typography
            variant="body1"
            className={classes.pmgId}          
            onClick={()=>openProductMatchingGroup(pmg.id)}
          >
            {pmg.id}
          </Typography>
        </TableCell>
        <TableCell align="center" width={'70px'}>
          < ProductImage  product_matching_group_id={pmg.id} 
            title={pmg.name} width={'50px'} height={'50px'}
            hide_multiple_icon={true}
          />
         
        </TableCell>        
        <TableCell align="left">
          {pmg.name}
          <Tags productMatchingGroupId={pmg.id}/>
        </TableCell>
      </TableRow>
    </Slide>
  );
}
