import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  tableTd: {
    padding: "0px",
  }, 
}));

export default function ProductPricesInfo({productId, limit=3}) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const token = useSelector((state) => state.session.token);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product/get-prices`;
      const postData = {
        limit: limit,
        id: productId,
      };
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
    };
    isVisible&&fetchData();
  }, [productId,isVisible]);
  
  return (
    <VizSensor onChange={doLoadData}>
       <div>
        {!isVisible?(
            <CircularProgress size={16} />
          ):(
          <Table className={classes.table}>
            <TableBody>
              {dataSource &&
                dataSource.map((storeProductPrice,i) => (
                  <TableRow key={`spp_tbl_row_${i}`}>
                    <TableCell className={classes.tableTd}>
                      {storeProductPrice.store_name}
                    </TableCell>
                    <TableCell className={classes.tableTd}>
                      ${storeProductPrice.current_price_ea}
                      {/* /Reg:${storeProductPrice.regular_price_ea} */}
                    </TableCell>
                    <TableCell className={classes.tableTd}>
                      {storeProductPrice.sold_by_type_name}
                    </TableCell>
                    <TableCell className={classes.tableTd}>
                      {storeProductPrice.sale_description}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          )}
      </div>       
    </VizSensor>
  );
}
