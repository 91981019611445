import React, { useState, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Button,
  Paper
} from "@material-ui/core";

import TagListItem from "./TagListItem";
import ItemLoading from "./ItemLoading";

import SearchBox from "./SearchBox";
import {  useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function TagListPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("pmg_count");
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceSearch, setDataSourceSearch] = useState([]);
  const [reloadData, setReloadData] = useState(0);
    
  const token = useSelector(state => state.session.token);
  const loader = document.querySelector(".loader");
  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  }

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/tagging/cloud`;
      const result = await axios.get(
        url,
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      setDataSource(result.data.data);
      setIsLoading(false);
      hideLoader();      
    };
    fetchData();
  }, [reloadData]);

  const doSearch = (q) => {
    setSearchText(q);
  };
  const handleSortClick = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
    let orderDirection = isAsc ? 1 : -1;
    let tempDataSource = [...dataSourceSearch]
    column =='id'||column =='pmg_count'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => ((a[column] > b[column])? 1 : ((b[column] > a[column]) ? -1 : 0))*orderDirection);
    setDataSourceSearch(tempDataSource);
    
  };
  useEffect(() => {    
    if (searchText.length > 0) {
      let dataSourceFilterTemp = dataSource.filter(function(tag) {      
          if ((tag.id.toString()===searchText.toString())||
              tag.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
      });
      setDataSourceSearch([...dataSourceFilterTemp]);
    } else {
      setDataSourceSearch([...dataSource]);
    }
  }, [searchText,dataSource]);
  
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Tags Management
          </Typography>
          <SearchBox doSearch={doSearch}  placeholder={"Search by tag's name"} value={searchText} searchOnChange={false} />
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
         
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                  <TableSortLabel
                        active={orderBy === "id"}
                        direction={orderBy === "id" ? order : "asc"}
                        onClick={() => handleSortClick("id")}
                      >
                        ID
                        {orderBy === "id" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel> 
                    </TableCell>
                  <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleSortClick("name")}
                      >
                        Name
                        {orderBy === "name" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                  <TableSortLabel
                        active={orderBy === "pmg_count"}
                        direction={orderBy === "pmg_count" ? order : "asc"}
                        onClick={() => handleSortClick("pmg_count")}
                      >
                        Total products
    
                        {orderBy === "pmg_count" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                                      </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSourceSearch.map((row, i) => (
                  // <span>{row.image_url}</span>
                  <TagListItem key={i} tag={row}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
