import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import SearchBox from "../SearchBox";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import {
  fetchCategories
} from "../../../actions/categories";
import { useDispatch, useSelector } from "react-redux";

import { Helpers } from "../../../utility/helpers";

import TableRowLoadMore from "../TableRowLoadMore";
import ReviewPanel from "./PbsReviewInOnePage/ReviewPanel";
import ReviewPanelNoSelectedGroup from "./PbsReviewInOnePage/ReviewPanelNoSelectedGroup";
import TotalBlock from "./PbsReviewInOnePage/TotalBlock";
import TotalLeftBlock from "./PbsReviewInOnePage/TotalLeftBlock";

import PotentialLeft from "./PbsReviewInOnePage/PotentialLeft";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
  toolbarRight: {
    minHeight: "64px",
  },
  toolbarTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width:"100%"
  },
  
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerFooter: {
    borderTop: "2px solid green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "18px",
    //paddingBottom: "px",
  },
  drawerFooterContainer: {
    height: "300px",
    width: drawerWidth,
    overflow: "auto",
  },
  drawerFooterImage: {
    width: 200,
    height: 200,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },
  productImage: {
    objectFit: "contain",
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sliderContainer: {
    width: 300,
    paddingRight: 20,
  },
  totalBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    minHeight: "64px",
    backgroundColor: theme.palette.primary.main,
    // fontSize:"36px",
    color: "#FFF",
  },
  selectListGroup:{
    color: theme.palette.secondary.main,
    // fontSize:"1.2rem",
    backgroundColor:"#fff",
    marginRight:"8px",
    '& div':{
      paddingLeft:"8px"
    }
  },
  coopListLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  totalLeftBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap:"nowrap",
    width: "100%",
    minHeight: "30px",
    backgroundColor: theme.palette.secondary.main,
    fontSize:"1rem",
    color: "#FFF",
  },
}));
const ThresholdSlider = withStyles({
  root: {
    color: "#52af77",
    // height: 8,
    padding: 0,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "1px solid #fff",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    color: "#52af77",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);
export default function PbsReviewInOnePage(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch()

  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(30);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("no");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [acceptedGroup, setAcceptedGroup] = useState([]);
  const [doNotShowOption, setDoNotShowOption] = useState(0);
  // const [wrongGroup, setWrongGroup] = useState([]);
  const [simValue, setSimValue] = useState(0.4);
  const [simBrandValue, setSimBrandValue] = useState(false);
  const [simSizeValue, setSimSizeValue] = useState(false);
  
  const [showMatchedProducts, setShowMatchedProducts] = useState(true);
  const [showMyAssignedProducts, setShowMyAssignedProducts] = useState(true);
  
  // const alertPopup = React.createRef();
  const totalBlock = React.createRef();
  const totalLeftBlock = React.createRef();
  
  // const openAlertPopup = (severity, message) => {
  //   alertPopup.current.doAlert(severity, message);
  // };
  
  const categories = useSelector(state => state.categories.categories);
  const token = useSelector(state => state.session.token);

  const [list2MatchingStores, setList2MatchingStores] = useState([]);
  const [selectedList2MatchingStoreId, setSelectedList2MatchingStoreId] = useState(1); 

  const [simSource, setSimSource] = useState("_ve");
  const [showMatchNumbers, setShowMatchNumbers] = useState("-1");
  useEffect(() => {
    categories.length===0&&dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {   
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/list?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        {
          q: searchText,
          categoryId: selectedCategoryId,
          showMatchNumbers: showMatchNumbers,
          simSource: simSource,
          list2MatchingStoreId: selectedList2MatchingStoreId,
          doNotShowOption: doNotShowOption,
          showMyAssignedProducts:showMyAssignedProducts
        },
        {
          headers: {
            Authorization: JSON.stringify(token),
          },
        }
      );
      
      if (skip === 0) {
        setGroups(result.data.data);
        
        if (selectedGroup) {
          const found = result.data.data.some(
            (el) => el.id === selectedGroup.id
          );
          !found && setSelectedGroup(result.data.data[0]);
        } else {
          setSelectedGroup(result.data.data[0]);
          // console.log(result.data.data[0]);
        }
      } else {
        let myNewGroup =  [...groups, ...result.data.data]
        setGroups([...new Set(myNewGroup)]);
      }
      setLoadMore(result.data.data.length === limit);     
    };

    fetchData();
  }, [
    searchText,
    selectedCategoryId,
    skip,
    order,
    orderBy,
    showMatchNumbers,
    simSource,
    selectedList2MatchingStoreId,
    doNotShowOption,
    showMyAssignedProducts
  ]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);    
  };
  
  const moveToNextGroup = ()=>{
    // console.log(selectedGroup)
    if (selectedGroup && selectedGroup.id > 0) {
      let retGroups = groups.filter((p) => p.id != selectedGroup.id);
      if(retGroups.length > 0) {
        //Still have groups left to select
        setGroups(retGroups);        
        setSelectedGroup(retGroups[0]);
        setAcceptedGroup([
          selectedGroup,
          ...acceptedGroup.filter((p) => p.id != selectedGroup.id),
        ]);
      }else{
      //No groups left
        setGroups(retGroups); 
        setSelectedGroup(null);
        setAcceptedGroup([
          selectedGroup,
          ...acceptedGroup.filter((p) => p.id != selectedGroup.id),
        ]);
      }      
    }    
  }
  const handleAccept = (e) => {
    moveToNextGroup()
  };
  const handleAllWrong = (e) => {
    moveToNextGroup()
  };
  const handleLeaveIt = (e) => {
    moveToNextGroup()
  };
  
  // const handleWrongClick = (product)=>{
  //   let tempGroup = wrongGroup;
  //   if(tempGroup.length>3) tempGroup.pop();
  //   tempGroup.unshift(product);
  //   setWrongGroup(tempGroup);
  // }
  const handleSliderChange = (event, newValue) => {
    setSimValue(newValue);
  };
 

  useEffect(() => {
    const fetchDataListGroup = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/test/list-store`;
      const result = await axios.get(url);
      setList2MatchingStores(result.data.data);
    };
    fetchDataListGroup();
  }, []);

  useEffect(() => {
    totalBlock.current.fetchTotalData(simSource,selectedList2MatchingStoreId);
  }, [simSource,selectedList2MatchingStoreId, selectedGroup]);

  useEffect(() => {
    totalLeftBlock.current.fetchTotalLeftData(searchText,selectedCategoryId,showMatchNumbers, simSource,selectedList2MatchingStoreId,doNotShowOption,showMyAssignedProducts);
  }, [searchText,selectedCategoryId, showMatchNumbers,simSource,selectedList2MatchingStoreId, selectedGroup,doNotShowOption,showMyAssignedProducts]);
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
    setSkip(0);
  };
  const handleSelectedGroupClick = (group) => {
    setSelectedGroup(group);
  };
 
  const handleSimSourceChange = (event) => {
    setSimSource(event.target.value);
  };

  const handleShowMatchNumbersChange = (event) => {
    setShowMatchNumbers(event.target.value);
    setSkip(0);
  };
  const handleList2MatchingStoreIdChange = (event) => {
    setSelectedList2MatchingStoreId(event.target.value);
    setSkip(0);
  };
  const handleDoNotShowOptionChange = (event) => {
    setDoNotShowOption(event.target.value);
    setSkip(0);
  };
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {selectedGroup && selectedGroup.product && (
              <>
                {/* {selectedGroup.product_upc}{`. `}   */}
                {selectedGroup.product.fullname}
                {selectedGroup.product.size &&
                  `(${selectedGroup.product.size})`}
              </>
            )}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={showMyAssignedProducts}
                onChange={() => setShowMyAssignedProducts(!showMyAssignedProducts)}
                name="Show my assigned"
                color="secondary"
              />
            }
            title="Show my assigned products only, uncheck to show all products"
            label="Show my assigned"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showMatchedProducts}
                onChange={() => setShowMatchedProducts(!showMatchedProducts)}
                name="Show matched"
                color="secondary"
              />
            }
            label="Show matched"
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={simBrandValue}
                onChange={() => setSimBrandValue(!simBrandValue)}
                name="Brand"
                color="secondary"
              />
            }
            label="Brand"
          /> */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={simSizeValue}
                onChange={() => setSimSizeValue(!simSizeValue)}
                name="Size"
                color="secondary"
              />
            }
            label="Size"
          /> */}
          <div className={classes.sliderContainer}>
            <Typography id="discrete-slider-always" gutterBottom>
              Threshold
            </Typography>
            <ThresholdSlider
              value={simValue}
              onChange={handleSliderChange}
              aria-labelledby="discrete-slider-always"
              step={0.1}
              // marks
              min={0}
              max={1}
              valueLabelDisplay="on"
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.totalBlock}>
          <Select className={classes.selectListGroup}  value={selectedList2MatchingStoreId} onChange={handleList2MatchingStoreIdChange} > 
              {list2MatchingStores.length > 0 &&
                list2MatchingStores.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
          </Select>
           <TotalBlock ref={totalBlock} />
        </div>
        <div className={classes.toolbar}>
          <div className={classes.toolbarTop} style={{ paddingTop: "18px" }}>
            <Select value={simSource} onChange={handleSimSourceChange}>
              <MenuItem value="_ve">
                <em>ML Minhash</em>
              </MenuItem>
              <MenuItem value="_pbs">
                <em>NER PgSim</em>
              </MenuItem>
              <MenuItem value="_ner">
                <em>NER SpSim</em>
              </MenuItem>
            </Select>  
            <Select value={showMatchNumbers} onChange={handleShowMatchNumbersChange}>
              <MenuItem value="-1">
                - Show all -
              </MenuItem>
              <MenuItem value="0">
                Not done yet
              </MenuItem>
              <MenuItem value="1">
                Only 1 match
              </MenuItem>
              <MenuItem value="2">
                Only 2 matches
              </MenuItem>
              <MenuItem value="3">
                Only 3 matches
              </MenuItem>
              <MenuItem value="4">
                New matches only
              </MenuItem>
            </Select>           
          </div>
          <div className={classes.toolbarTop} style={{ paddingTop: "18px" }}>
          {/* <Select value={selectedCategoryId} onChange={handleCategoryChange}>
              <MenuItem value="0">
                <em>All categories</em>
              </MenuItem>
              {categories.length > 0 &&
                categories.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                ))}
            </Select> */}
            <Select value={doNotShowOption} onChange={handleDoNotShowOptionChange}>
              <MenuItem value="0" alt="Display all products">
                - Show all -
              </MenuItem>
              <MenuItem value="1" alt="Do not display products that have been reviewed by me">
                Don't show P's checked by me
              </MenuItem>
              <MenuItem value="2" alt="Do not display products that have been reviewed by everyone">
                Don't show P's checked by everyone
              </MenuItem>
              <MenuItem value="3" alt="Show products that I click on 'leave it' button">
                Show "leave it" by me 
              </MenuItem>
              <MenuItem value="4" alt="Show products that someone else clicks on 'leave it' button">
                Show "leave it" by someone else
              </MenuItem>                
              <MenuItem value="5" alt="Show products that I click on 'accepted' button">
                Show "Accepted" by me
              </MenuItem>             
              <MenuItem value="6" alt="Show products that I click on 'wrong all' button">
                Show "Wrong all" by me
              </MenuItem>             
            </Select> 
          </div>
        </div>
        <div className={classes.toolbar}>
         
          <SearchBox
            doSearch={doSearch}
            value={searchText}
            searchOnChange={false}
          />{" "}
        </div>
        <Divider />
        <div className={classes.drawerContainer}>
          <List className={classes.listProducts}>
            {groups.length > 0 &&
              groups.map((group, index) => (
                <ListItem
                  button
                  key={group.id}
                  selected={
                    selectedGroup ? group.id === selectedGroup.id : false
                  }
                  onClick={() => handleSelectedGroupClick(group)}
                >
                  <ListItemIcon>
                    <img
                      className={classes.productImage}
                      alt={group.product.name}
                      src={group.product.image_url
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/resources/images/no-picture-available.png';
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${group.no}.${ Helpers.titleCase(group.product.name)}`}
                    secondary={`${group.product.size} #${group.product_upc}`}
                  />
                  <ListItemSecondaryAction>
                    {/* {!group.accept_count && (
                      <Chip label={group.matched_count} color="primary" />
                    )} */}
                    {/* {group.accept_count > 0 && (
                      <Badge color="primary" badgeContent={group.accept_count}>
                        <Chip label={group.matched_count} color="secondary" />
                      </Badge>
                    )} */}
                    <PotentialLeft simSource={simSource} simValue={simValue} matched={group.matched_count} groupId={group.id}/>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          {loadMore && <TableRowLoadMore doLoadMore={doLoadMore} colSpan={4} />}
        </div>
        <div className={classes.totalLeftBlock}>
          Total on list: <TotalLeftBlock ref={totalLeftBlock} />
        </div>
        <div className={classes.drawerFooter}>
          <div className={classes.drawerFooterContainer}>
            <List className={classes.listProducts}>
              {acceptedGroup.length > 0 &&
                acceptedGroup.map((group, index) => (
                  <ListItem
                    button
                    key={group.id}
                    selected={
                      selectedGroup ? group.id === selectedGroup.id : false
                    }
                    onClick={() => handleSelectedGroupClick(group, true)}
                  >
                    <ListItemIcon>
                      <img
                        className={classes.productImage}
                        alt={group.product.name}
                        src={
                          group.product.image_url
                            ? group.product.image_url
                            : "/resources/images/no-picture-available.png"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${group.no}.${ Helpers.titleCase(group.product.name)}`}
                      secondary={`${group.product.size} #${group.product_upc}`}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
          <Link to={`/matching/pbs/coop`} className={classes.coopListLink}>
            Review the marked "wrong" products
          </Link>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbarRight} />
        <div className={classes.reviewPanel}>
          {selectedGroup && (
            <ReviewPanel
              selectedGroup={selectedGroup}
              simValue={simValue}
              simBrandValue={simBrandValue}
              simSizeValue={simSizeValue}
              simSource={simSource}
              handleAccept={handleAccept}
              handleAllWrong={handleAllWrong}
              handleLeaveIt={handleLeaveIt}
              showMatchedProducts={showMatchedProducts}
            />
          )}
          {!selectedGroup && (
            <ReviewPanelNoSelectedGroup />
          )}
        </div>
        
      </main>
      {/* <Alert ref={alertPopup} /> */}
    </div>
  );
}
