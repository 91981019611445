import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDispatch, useSelector } from "react-redux";
import {
    setCompanies,fetchCompanies
  } from "../../actions/companies";  

const useStyles = makeStyles((theme) => ({
  
}));

export default function CompanyDropDownBox() {
  const classes = useStyles();
  const companies = useSelector(state => state.companies.companies);
  const selectedCompany = companies&&companies.find(company=>company.selected);
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);

 const handleOpenCompanyMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCompanyMenu = (id) => {
    setAnchorEl(null);
    const companiesTemp = companies.map(company=>{
        if(company._id===id){
          company.selected = true;
        }else{
          company.selected = false;
        }
        return company;
    });
    dispatch(setCompanies(companiesTemp));
  };
  useEffect(() => {
    companies.length===0&&dispatch(fetchCompanies());
  }, [dispatch]);
  return (
    <>
    <Button color="primary" className={classes.buttonCategoryMenu} onClick={handleOpenCompanyMenu}
           endIcon={<ExpandMoreIcon/>}
          >{selectedCompany?selectedCompany.name:'All Store/Chain'}</Button>
    <Menu
        id="company-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseCompanyMenu}
      >
        <MenuItem onClick={handleCloseCompanyMenu}>All Store/Chain</MenuItem>
        {companies&&companies.map((company)=>
          <MenuItem key={company._id} selected={company.selected} onClick={()=>handleCloseCompanyMenu(company._id)}>{company.name}</MenuItem>
        )}
    </Menu>
    </>    
  );
}
