import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";
import EditPMGTextBox from "./EditPMGTextBox";
import CountProductsInGroup from "../CompanyReportTool/Products/CountProductsInGroup";
import CountBannersInGroup from "../CompanyReportTool/Products/CountBannersInGroup";
import Tags from "../Tags/Tags";
const useStyles = makeStyles((theme) => ({
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  productName: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  removeButton: {
    color: "red",
    borderColor: "red",
  },
}));

export default function ProductMatchingGroupListItem({
  productMatching,
  openMergeGroupDialog,
  openSimilarGroupDialog,
}) {
  const history = useHistory();
  const classes = useStyles();

  const [direction, setDirection] = useState("right");
  const [checked, setChecked] = useState(true);
  const handGroupView = (groupId) => {
    window.open(`matching-group/${groupId}`);
  };
  const handGroupRemove = (groupId) => {
    const r = window.confirm("Do you really want to remove this group?");
    if (r == true) {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/remove/${groupId}`;
      // console.log(postData);
      axios
        .delete(url, {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        })
        .then((response) => {
          // console.log(response);
          setChecked(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handSimilarGroupMap = (productMatchingGroup) => {
    //history.push(`matching-group/${groupId}`);
    // alert('open pop to choose other group to map similar');
    openSimilarGroupDialog(productMatchingGroup);
  };
  const handMergeGroups = (productMatchingGroup) => {
    //history.push(`matching-group/${groupId}`);
    openMergeGroupDialog(productMatchingGroup);
  };
  let productImageUrl = productMatching.image_url;
    
  return (
    <Slide direction={direction} in={checked} mountOnEnter unmountOnExit>
      <TableRow key={productMatching.id}>
        <Hidden mdDown>
          <TableCell align="center">{productMatching.id}</TableCell>
          <TableCell align="center">
            {/* <Button
            color="secondary"
            size="small"
            onClick={() => handGroupView(productMatching.id)}
          >
            View
          </Button>&nbsp; */}
            <IconButton
              size="small"
              className={classes.removeButton}
              onClick={() => handGroupRemove(productMatching.id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <img
              className={classes.productImage}
              src={productImageUrl}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/resources/images/no-picture-available.png';
              }}
              // onClick={() => handGroupView(productMatching.id)}
            />
          </TableCell>
        </Hidden>

        <TableCell align="left">
        <Typography
          variant="body1"
          className={classes.productName}
            onClick={()=>handGroupView(productMatching.id)}
          >
            {productMatching.name}
          </Typography>
        </TableCell>
        <TableCell align="center">{productMatching.brand_name}</TableCell>
        <TableCell align="center">{productMatching.size}</TableCell>
        {/* <TableCell align="center">
          <EditPMGTextBox
            pmgId={productMatching.id}
            value={productMatching.label}
            field="label"
          />
        </TableCell>
        <TableCell align="center">{productMatching.products_count}</TableCell> */}
        {/* <TableCell align="center">{productMatching.match_score_avg}</TableCell> */}
        <TableCell style={{width:'160px'}} align="left">
          <CountProductsInGroup productMatchingGroupId={productMatching.id}/> 
          <CountBannersInGroup productMatchingGroupId={productMatching.id}/>
        </TableCell>
        <TableCell align="center"><Tags productMatchingGroupId={productMatching.id}/></TableCell>

        <TableCell align="center">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => handSimilarGroupMap(productMatching)}
          >
            Similar
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            // className={classes.removeButton}
            onClick={() => handMergeGroups(productMatching)}
          >
            Merge
          </Button>
        </TableCell>
      </TableRow>
    </Slide>
  );
}
