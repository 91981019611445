import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import VizSensor from "react-visibility-sensor";

const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.secondary.main,
  },
}));

export default function ItemLoading({ doLoadMore, colSpanValue = 3 }) {
  const classes = useStyles();

  return (
    <VizSensor onChange={doLoadMore}>
      <TableRow>
        <TableCell colSpan={colSpanValue} align="center">
          <CircularProgress className={classes.loading} />
        </TableCell>        
      </TableRow>
    </VizSensor>
  );
}
