import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import CountTaggedProducts from "./CountTaggedProducts";

const useStyles = makeStyles((theme) => ({
  tagName: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: "pointer"
  }, 
}));

export default function TagListItem({
  tag,
}) {
  // const history = useHistory();
  const classes = useStyles();
  
  const handTagDetail = (tag) => {
    window.open(`/tag/detail/${tag.id}/${encodeURIComponent(tag.name)}`);
  };
  return (
      <TableRow key={tag.id}>
        <TableCell align="left">{tag.id}</TableCell>
        <TableCell align="left">
          <Typography
          variant="body1"
          className={classes.tagName}
            onClick={()=>handTagDetail(tag)}
          >
            {tag.name}
          </Typography>
        </TableCell>
        <TableCell align="left"><CountTaggedProducts tagId={tag.id}/></TableCell>        
      </TableRow>    
  );
}
