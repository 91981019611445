import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,  
  Button,
  Paper
} from "@material-ui/core";
import CountProductsInGroup from "./CountProductsInGroup";
import CountBannersInGroup from "./CountBannersInGroup";
import EditActualSizeTextBox from "./EditActualSizeTextBox";
import ButtonRemoveBProduct from "./ButtonRemoveBProduct";
import ProductImage from "./ProductImage";
// import BProductsAndCompetitorPanel from "./Products/BProductsAndCompetitorPanel";


import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import ProductAttributesInfo from "../../Product/ProductAttributesInfo";
import Tags from "../../Tags/Tags";
const useStyles = makeStyles((theme) => ({
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  productName: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  removeButton: {
    color: "red",
    borderColor: "red",
  },
}));
export default function BProductsPanel({item,reloadDataCount,searchText,projectId}) {  
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadDataLocal, setReloadDataLocal] = useState(reloadDataCount);
  const [dataSource, setDataSource] = useState([]);
  const token = useSelector((state) => state.session.token);
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  
  const handleAddMoreMatch = (_product_id)=>{
    window.open(`/matching-potential-4project/${_product_id}/${projectId}`);
  } 

  const handleCreateAndMatch = (productMatchingGroupID)=>{
    window.open(`/matching-group/${productMatchingGroupID}`);
  } 
  const removeItem=(i)=>{   
    setReloadDataLocal(reloadDataLocal+1)
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/product/detail?id=${item.id}`;
      const result = await axios.post(url,{q:searchText}, {
        headers: {
          Authorization: token,
        },
      });
      // console.log(result.data.data)
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    isVisible&&fetchData();

    // if (searchText.length > 0) {
    //   let dataSourceTemp = products.filter(function(product) {
    //     if (product.productMatchingGroup&&
    //       (product.productMatchingGroup.fullname.toLowerCase().includes(searchText.toLowerCase())||
    //       (product.productMatchingGroup.id.toString()===searchText.toString()))
    //     )  {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    //   setDataSource([...dataSourceTemp]);
    // } else {
    //   setDataSource([...products]);
    // }
  }, [searchText,item,reloadDataLocal,isVisible,reloadDataCount]);
  return (
      <VizSensor onChange={doLoadData}>    
        <Table >
          <TableBody> 
            {dataSource.length==0&&(
              <TableRow >
                <TableCell colSpan={7} align="center">
                    No product matching group 
                </TableCell>
              </TableRow>
            )} 
            {dataSource.map((item, i) => (               
              <TableRow key={i}>
                <TableCell style={{width:'20px'}} align="center">
                  {i+1}
                </TableCell>
                <TableCell style={{width:'16px'}} align="center">
                    <ButtonRemoveBProduct item={item} removeItem={i=>removeItem(i)}/> 
                </TableCell>
                <TableCell style={{width:'80px'}}>
                  <ProductImage image_url={item.productMatchingGroup.image_url}/>
                </TableCell>
                <TableCell style={{width:'50px'}}>
                  {/* <Button  variant="outlined" color="primary" onClick={()=>handleAddMoreMatch(item.productMatchingGroup._product_id)}> */}
                  <Button  variant="outlined" color="primary" onClick={()=>handleCreateAndMatch(item.productMatchingGroup.id)}>
                  {item.productMatchingGroup.id}
                  </Button>
                  
                </TableCell>
                <TableCell >
                  <div>{item.productMatchingGroup.fullname}</div>
                  <div>{item.productMatchingGroup.size}</div>
                  <ProductAttributesInfo productId={item.productMatchingGroup.product_id}/>
                  <Tags productMatchingGroupId={item.productMatchingGroup.id}/>
                </TableCell>            
                <TableCell style={{width:'200px'}}>
                  {item.productMatchingGroup.brand_name}
                </TableCell>
                <TableCell style={{width:'120px'}}>
                  <EditActualSizeTextBox id={item.id} value= {item.actual_size}/>
                </TableCell>  
                <TableCell style={{width:'160px'}} align="left">
                  <CountProductsInGroup productMatchingGroupId={item.productMatchingGroup.id}/> 
                  <CountBannersInGroup productMatchingGroupId={item.productMatchingGroup.id}/>
                </TableCell>
                                    
              </TableRow>
             ))}     

          </TableBody>
        </Table>
    </VizSensor>   
  );
}
