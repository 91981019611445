import React, { useState, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

import ProductMatchingGroupListItem from "./ProductMatchingGroupListItem";
import ProductMatchingGroupListLoading from "./ProductMatchingGroupListItemLoading";

import MergeGroupDialog from "./MergeGroupDialog";
import SimilarGroupDialog from "./SimilarGroupDialog";


import SearchBox from "./SearchBox";
import CompanyDropDownBox from "./CompanyDropDownBox";
import {  useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function Products2ReviewPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [groups, setGroups] = useState([]);
  const [isMergeGroupDialogOpen, setIsMergeGroupDialogOpen] = useState(false);
  const [isSimilarGroupDialogOpen, setIsSimilarGroupDialogOpen] = useState(false);
  const [currentProductMatchingGroup, setCurrentProductMatchingGroup] = useState(undefined);
  const companies = useSelector(state => state.companies.companies);
  const token = useSelector(state => state.session.token);
  const selectedCompany = companies&&companies.find(company=>company.selected);

  const loader = document.querySelector(".loader");
  // const updateContainer = document.querySelector(".updateContainer");

  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  }

  useEffect(() => {
    const fetchData = async () => {
      if (skip === 0) {
        showLoader();
        setIsLoading(true);
      }
      // console.log('here'+searchText);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/groups?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}&c=${selectedCompany?selectedCompany._id:0}`;
      const result = await axios.post(
        url,
        { q: searchText },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      // console.log(result.data.data);
      // console.log('search')
      if (skip === 0) {
        setGroups(result.data.data);
      } else {
        //   console.log(result.data.data);
        //   console.log('herer');
        setGroups([...groups, ...result.data.data]);
      }
      if (skip === 0) {
        setLoadMore(result.data.data.length === limit);
        setIsLoading(false);
        hideLoader();
      }
    };

    fetchData();
  }, [searchText, skip, order, orderBy, selectedCompany]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  const handleSortClick = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSkip(0);
  };
  const handViewSimilarGroupsClick = ()=>{
    history.push("/similar-groups");
  }

  const openMergeGroupDialog = (productMatchingGroup) => {
    setCurrentProductMatchingGroup(productMatchingGroup);
    setIsMergeGroupDialogOpen(true);
  };
  const handleMergeGroupDialogClose = () => {
    setIsMergeGroupDialogOpen(false);
  };
  const openSimilarGroupDialog = (productMatchingGroup) => {
    setCurrentProductMatchingGroup(productMatchingGroup);
    setIsSimilarGroupDialogOpen(true);
  };
  const handleSimilarGroupDialogClose = () => {
    setIsSimilarGroupDialogOpen(false);
  };

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Product matching groups
          </Typography>
          <CompanyDropDownBox/>
          <SearchBox doSearch={doSearch} value={searchText} searchOnChange={false} />&nbsp;
          <Button variant="contained" color="primary" onClick={handViewSimilarGroupsClick}>
          <Hidden mdDown> View </Hidden> Similar <Hidden mdDown>Groups</Hidden>
          </Button>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
         
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <Hidden mdDown>
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === "group_id"}
                        direction={orderBy === "group_id" ? order : "asc"}
                        onClick={() => handleSortClick("group_id")}
                      >
                        ID
                        {orderBy === "group_id" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Remove</TableCell>
                    <TableCell align="center">Image eg.</TableCell>
                  </Hidden>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={() => handleSortClick("name")}
                    >
                      Name eg.
                      {orderBy === "name" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "brand_name"}
                      direction={orderBy === "brand_name" ? order : "asc"}
                      onClick={() => handleSortClick("brand_name")}
                    >
                      Brand.
                      {orderBy === "brand_name" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === "size"}
                      direction={orderBy === "size" ? order : "asc"}
                      onClick={() => handleSortClick("size")}
                    >
                      Size eg.
                      {orderBy === "size" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell align="center">Label</TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={orderBy === "products_count"}
                      direction={orderBy === "products_count" ? order : "asc"}
                      onClick={() => handleSortClick("products_count")}
                    >
                      #Products.
                      {orderBy === "products_count" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell> */}
                  {/* <TableCell align="right">
                    <TableSortLabel
                      active={orderBy === "match_score_avg"}
                      direction={orderBy === "match_score_avg" ? order : "asc"}
                      onClick={() => handleSortClick("match_score_avg")}
                    >
                      Avg Score.
                      {orderBy === "match_score_avg" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell> */}
                  <TableCell align="center">Counts</TableCell>
                  <TableCell align="center">Tags</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((row, i) => (
                  // <span>{row.image_url}</span>
                  <ProductMatchingGroupListItem key={i} productMatching={row} openMergeGroupDialog={openMergeGroupDialog} openSimilarGroupDialog={openSimilarGroupDialog}/>
                ))}
                {loadMore && (
                  <ProductMatchingGroupListLoading doLoadMore={doLoadMore} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      <MergeGroupDialog open={isMergeGroupDialogOpen} onClose={handleMergeGroupDialogClose} currentProductMatchingGroup={currentProductMatchingGroup}/>
      <SimilarGroupDialog open={isSimilarGroupDialogOpen} onClose={handleSimilarGroupDialogClose} currentProductMatchingGroup={currentProductMatchingGroup}/>
    </>
  );
}
