import React, { useState, useEffect } from "react";
import { useHistory, Link} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Button,
  Paper
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import ButtonAdd from "./Products/ButtonAdd";
import ButtonEdit from "./Products/ButtonEdit";
import ButtonDelete from "./Products/ButtonDelete";
import ButtonCalculateMatches from "./Products/ButtonCalculateMatches";
// import CountCompetitorProducts from "./Products/CountCompetitorProducts";
// import CountBProducts from "./Products/CountBProducts";
import SearchBox from "./Products/SearchBox";
import CountSProductsInCProduct from "./Products/CountSProductsInCProduct";
import CountBannersInCProduct from "./Products/CountBannersInCProduct";
import ProductLoadMore from "./Products/ProductLoadMore";
import BProductsPanel from "./Products/BProductsPanel";
import CompetitorsPanel from "./Products/CompetitorsPanel";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  searchContainer: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },
  },
  table: {
    minWidth: 350,
    // "& tbody>.MuiTableRow-root:hover": {
    //   background: "#DDD",
    // },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export function RowItem(props){
  const {item, i, doReloadData,expanded, searchText,projectId} = props;
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [reloadDataCount, setReloadDataCount] = useState(0);
  
  useEffect(() => {
    //regenerate document;
    setIsExpanded(expanded)
  }, [expanded]);
  useEffect(() => {   
  }, [searchText]);
  const doReloadCount = ()=>{
    setReloadDataCount(reloadDataCount+1)
  }
  return (
    <>
      <TableRow style={{background:'#f0f0f3'}}  >
        <TableCell title={item.id+' - '+item.name} align="center" rowSpan={isExpanded?3:1} style={{background:i%2?'#eafaea':'#e6ecff',cursor:'pointer',width:'50px'}} onClick={()=>setIsExpanded(!isExpanded)} >
            {i+1}         
        </TableCell>
        <TableCell align="left" style={{width:'100px'}}>
          <ButtonEdit product={item} reloadData={doReloadData}/> 
          <ButtonDelete product={item} reloadData={doReloadData}/> 
          <ButtonAdd product={item} reloadData={doReloadCount}/> 
        </TableCell>                   
        <TableCell align="left">{item.id}</TableCell>
        <TableCell align="left">{item.name}<br/>
        <i style={{fontSize:"0.8em"}}><b>Key words:</b>{item.key_words}</i>
        </TableCell>
        {/* <TableCell align="left">{row.type}</TableCell> */}
        <TableCell align="left">{item.category}</TableCell>
        <TableCell align="left">{item.sub_category}</TableCell>
        {/* <TableCell align="left">{row.standard_size}</TableCell>
        <TableCell align="left">{row.actual_size}</TableCell> */}
        {/* <TableCell align="center">
          <CountBProducts companyReportToolProductId={row.id}/> 
        </TableCell> */}
        {/* <TableCell align="center">
          <CountCompetitorProducts companyReportToolProductId={row.id}/> 
        </TableCell> */}
        <TableCell style={{width:'68px'}}  align="center">
          {/* <CountSProductsInCProduct cproductId={item.id}/>  */}
        </TableCell>
        <TableCell style={{width:'68px'}}  align="center">
          {/* <CountBannersInCProduct cproductId={item.id}/> */}
        </TableCell>
      </TableRow> 
      {isExpanded&&(
        <>
        <TableRow >
          <TableCell colSpan={7}>
            <BProductsPanel item={item} reloadDataCount={reloadDataCount} searchText={searchText} projectId={projectId}/>
          </TableCell>
        </TableRow>
        <TableRow >
          <TableCell colSpan={7}>
            <CompetitorsPanel cproductId={item.id} reloadData={doReloadData}/>
          </TableCell>
        </TableRow>
        </>
      )}                        
    </>
  )
}
export default function CompanyReportToolProductPage(props) {
  const history = useHistory();
  const classes = useStyles();  
  const projectId = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("order_no")
  const [filter, setFilter]=useState({q:''})
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceSearch, setDataSourceSearch] = useState([]);
  const [reloadData, setReloadData] = useState(0);
  const [searchText, setSearchText] = useState('');  
  const [searchProjectProductOnly, setSearchProjectProductOnly] = useState(true);
  const [notMappedYet, setNotMappedYet] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);

  const token = useSelector((state) => state.session.token);
  
  const loader = document.querySelector(".loader");
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };
  const doReloadData =()=>{setReloadData(reloadData+1)}
  useEffect(() => {
    
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/products?project_id=${projectId}&orderBy=${orderBy}&order=${order}&limit=${10000}&skip=${skip}`;
      const result = await axios.post(url,
        {q:'',notMappedYet:notMappedYet}
        , {
        headers: {
          Authorization: token,
        },
      });
      
      setDataSource(result.data.data);
      // setDataSourceSearch(result.data.data);
      // console.log(result.data.data)
      
      setIsLoading(false);
      hideLoader();
    };
    fetchData();
  
}, [notMappedYet, order, orderBy,reloadData]);
const handleSort=(column)=>{
  setOrder(order==='desc'?'asc':'desc');
  setOrderBy(column);
  setSkip(0);  
}
useEffect(() => {
  // setLimit(20)
  
  if (searchText.length > 0) {
    let dataSourceFilterTemp = dataSource.filter(function(product) {      
        let dataSourceTemp = product.products.filter(function(bproduct) {   
            if (bproduct.productMatchingGroup&&
              (bproduct.productMatchingGroup.fullname.toLowerCase().includes(searchText.toLowerCase())||
              (bproduct.productMatchingGroup.id.toString()===searchText.toString()))
            ) {
              return true;
            } else {
              return false;
            }          
        });
        if ((product.id.toString()===searchText.toString())||
            product.name.toLowerCase().includes(searchText.toLowerCase())||
            product.category.toLowerCase().includes(searchText.toLowerCase())||
            product.key_words.toLowerCase().includes(searchText.toLowerCase())||
            product.sub_category.toLowerCase().includes(searchText.toLowerCase())||dataSourceTemp.length>0
        ) {
          return true;
        } else {
          return false;
        }
    });
    setDataSourceSearch([...dataSourceFilterTemp]);
    setLoadMore(dataSourceFilterTemp.length > 20);
  } else {
    setDataSourceSearch([...dataSource]);
    setLoadMore(dataSource.length > 20);
  }
 
}, [searchText,dataSource]);
const doLoadMore = (isVisible) => {
  setLoadMore(limit<dataSourceSearch.length)
  let tempLimit = limit +20  
  // console.log(tempLimit)
  isVisible && setLimit(tempLimit);
  
};
const doSearch = (searchValue) => {
  setSearchText(searchValue);
};
  return (
    <>
     {/* <CssBaseline /> */}
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Products of the project 
          </Typography>   
          <ButtonEdit project_id={projectId} reloadData={doReloadData}/> 
          &nbsp;      
          <ButtonCalculateMatches project_id={projectId} flow_type="product-similarity" />   
          &nbsp;      
          <ButtonCalculateMatches project_id={projectId} flow_type="product-matching" />   
              
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          {/* <div>Loading ...</div> */}
        </Grid>
      ) : (
        <>
        <Grid
          className={classes.searchContainer}
          container
          alignItems="center"
          justifyContent="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                  checked={notMappedYet}
                  onChange={() => setNotMappedYet(!notMappedYet)}
                  name="Not mapped yet"
                  color="secondary"
                />
              }
              label="Not mapped yet"
            />
          <FormControlLabel
            control={
              <Checkbox
                  checked={searchProjectProductOnly}
                  onChange={() => setSearchProjectProductOnly(!searchProjectProductOnly)}
                  name="Product Only"
                  color="secondary"
                />
              }
              label="Product Only"
            />
            <SearchBox value={searchText} doSearch={doSearch} searchOnChange={true} placeholder="Search"/>
         </Grid>
        <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
      >
          
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader aria-label="simple table">
            <TableHead >
              <TableRow>
                {/* <TableCell align="center">data_year_week</TableCell> */}
                <TableCell align="center">
                <IconButton  onClick={()=>setIsExpanded(!isExpanded)}>
                  {isExpanded&&<ExpandMoreIcon/>}
                  {!isExpanded&&<ExpandLessIcon/>}
                </IconButton>                  
                </TableCell>
                <TableCell align="left">
                  Command                
                </TableCell>
                <TableCell align="left" >                    
                  <SortLabel onClick={()=>handleSort('id')}>ID</SortLabel>
                </TableCell>                     
                <TableCell align="left" >                    
                  <SortLabel onClick={()=>handleSort('name')}>Name</SortLabel>
                </TableCell>
                {/* <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('type')}>Type</SortLabel>                    
                </TableCell> */}
                <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('category')}>Category</SortLabel>                    
                </TableCell>
                <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('sub_category')}>Sub Category</SortLabel>                    
                </TableCell>
                {/* <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('standard_size')}>Standard Size</SortLabel>                    
                </TableCell>
                <TableCell align="left">
                  <SortLabel onClick={()=>handleSort('actual_size')}>Actual Size</SortLabel>                    
                </TableCell>     */}
                {/* <TableCell align="left">
                  # PMG Ids                   
                </TableCell> */}
                {/* <TableCell align="left">
                  # Competitor                   
                </TableCell> */}
                <TableCell align="left">
                  # in Group                   
                </TableCell>
                <TableCell align="left">
                  # Banners                   
                </TableCell>                
                         
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSourceSearch.slice(0,limit).map((row, i) => (
                <RowItem key={i} item={row} i={i} doReloadData={doReloadData}  expanded={isExpanded} searchText = {searchProjectProductOnly?'':searchText} projectId={projectId}/>                
              ))}
              {loadMore && (
                <ProductLoadMore doLoadMore={doLoadMore} />
              )}
            </TableBody>             
          </Table>
        </TableContainer>
      </Grid>
      </>
      )}
    </>
  );
}
